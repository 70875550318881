import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"contact-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3 px-2 no-uppercase",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.exportCsvSample()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiFileDownload))]),_c('span',[_vm._v("Exportar CSV Ejemplo")])],1),_c(VBtn,{staticClass:"mb-4 me-3 px-2 no-uppercase",attrs:{"color":"warning"},on:{"click":function($event){return _vm.$refs.import_contact_modal.open()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiFileUploadOutline))]),_c('span',[_vm._v("Importar Lote")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.contactsListTable,"page":_vm.current_page,"server-items-length":_vm.totalContactsListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:page":function($event){return _vm.changingContactsPage($event)},"update:items-per-page":function($event){return _vm.changingContactsItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Lotes de importación")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.contact_batch_detail.open(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.processed_contacts",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{class:item.processed_contacts === item.total_contacts ? 'font-weight-bold': ''},[_vm._v(_vm._s(item.processed_contacts))]),_c('span',[_vm._v(_vm._s(' de '))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.total_contacts))])]),_c('div',[_c('span',[_vm._v("Teléfonos: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.total_phone_number ? item.total_phone_number : ' - '))])])]}},{key:"item.google_status",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase status-container pa-2",attrs:{"depressed":"","color":_vm.googleStatusColorParser(item.google_status.id),"max-height":"26px","min-width":"0"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.google_status.label))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(
              (item.percentage_progress_creation === 0 && item.status.id !== 0) ||
              item.percentage_progress_creation === 100 || item.status.id === 1
            )?_c(VBtn,{staticClass:"no-uppercase status-container pa-2",attrs:{"depressed":"","color":_vm.statusColorParser(item.status.id),"max-height":"26px","min-width":"0"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.status.label))])]):_c('div',{staticClass:"rounded-lg relative",staticStyle:{"width":"100%","background-color":"#bdbdbd","height":"26px","position":"relative"}},[_c('div',{staticClass:"rounded-lg",style:({
                width: item.percentage_progress_creation + '%',
                'background-color':
                  item.percentage_progress_creation < 25
                    ? '#F44336'
                    : item.percentage_progress_creation >= 25 &&
                      item.percentage_progress_creation <= 50
                    ? '#FF9800'
                    : item.percentage_progress_creation > 50 &&
                      item.percentage_progress_creation <= 75
                    ? '#FFEB3B'
                    : '#4CAF50',
                height: '26px',
              })}),_c('span',{staticStyle:{"position":"absolute","top":"2px","bottom":"0","left":"0","right":"0","color":"white"}},[_vm._v(_vm._s(item.percentage_progress_creation)+"%")])])]}},{key:"item.delete",fn:function(ref){
              var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.delete_batch_modal.open(item)}}},[_c(VIcon,{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}},{key:"item.google_sync",fn:function(ref){
              var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"primary","disabled":!item.google_account || item.google_status.id === 1 || item.status.id !== 1},on:{"click":function($event){return _vm.googleSync(item.id)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiLogin)+" ")])],1)]}}],null,true)})],1),_c('ContactBatchDetail',{ref:"contact_batch_detail"}),_c('ImportContactBatch',{ref:"import_contact_modal",on:{"reload-contact-list":function($event){return _vm.getContactBatches()},"display-alert":_vm.showCreationAlert}}),_c('DeleteContactBatch',{ref:"delete_batch_modal",on:{"reload-contact-list":function($event){return _vm.getContactBatches()},"display-alert":_vm.showCreationAlert}}),_c('alert-pop-up',{ref:"alert_pop_up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }